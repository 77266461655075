// add custom js below

import smoothscroll from 'smoothscroll-polyfill';
import 'owl.carousel';

// kick off the polyfill!
smoothscroll.polyfill();

$(document).ready(function () {
    // OwlInit
    $('.owl-carousel').owlCarousel({
        items: 1,
        lazyLoad: 1,
        loop: 1,
        mouseDrag: 1,
        touchDrag: 1,
        dots: 0,
        margin: 10,
        nav: 1,
        navText: ["", ""]
    });

    // Smooth Scrolls
    $('.smooth').click(function (e) {
        e.preventDefault();
        let scrollTo = $(this).attr('href');
        document.querySelector(scrollTo).scrollIntoView({
            behavior: 'smooth'
        });
    });
});